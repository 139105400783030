<template>
  <div class="class">
    <div class="loading-wrapper" v-show="showLoading">
      <Loading></Loading>
    </div>
    <div class="top">
      <p class="apply" style="font-size: 14px">共<span class="num" :v-model="total"> {{total}} </span>个培训班</p>
      <el-input class="inp" v-model="className"></el-input>
      <el-button class="el-but" type="primary" @click="getAllClassByMoHu()">搜索</el-button>
      <el-button class="addclass" type="primary" @click="getaddclass()">创建班级</el-button>
    </div>
    <el-table :data="dataList">
      <el-table-column fixed="left" label="班级名称" width="200px">
        <template slot-scope="scope">
          <span class="spani">{{ scope.row.className}}</span>
        </template>
      </el-table-column>
      <el-table-column label="开班总人数" align="center" width="90">
        <template slot-scope="scope">
          <span>{{scope.row.trainNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column label="班级人数" align="center" width="68">
        <template slot-scope="scope">
          <span>{{scope.row.classNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column label="已报名人数" align="center" width="80">
        <template slot-scope="scope">
          <span>{{scope.row.applyNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column label="办班地点" align="center" width="150px">
        <template slot-scope="scope">
          <span class="spani" style="text-align: left;">{{scope.row.trainLocation}}</span>
        </template>
      </el-table-column>
      <el-table-column label="课程类别" align="center" width="110">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.category}}</span>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" align="center" width="84">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.registerOpenTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="办班机构" align="center" width="160">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.sponsor}}</span>
        </template>
      </el-table-column>
      <el-table-column label="二维码" align="center" width="80px">
        <template slot-scope="scope">
          <span class="spani">
            <el-popover placement="right" trigger="click">
              <!--trigger属性值：hover、click、focus 和 manual-->
              <img :src="scope.row.codeUrl" height="400">
              <img slot="reference" :src="scope.row.codeUrl" height="50" width="50">
            </el-popover>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="550px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="classList(scope.row)">查看学员</el-button>
          <el-button size="mini" v-permission="['Classinformation:edit']" type="warning" @click="handleModify(scope)">修改
          </el-button>
          <el-button size="mini" v-permission="['Classinformation:delete']" type="danger" @click="delet(scope.row)">删除
          </el-button>
          <el-button size="mini" v-permission="['Classinformation:printing']" type="success">
            <el-link class="white" :href="scope.row.codeUrl+'?response-content-type=application/octet-stream'" download>
              打印二维码</el-link>
          </el-button>
          <el-button size="mini" type="primary" @click="Refresh(scope.row)">刷新二维码</el-button>
          <el-button size="mini" type="primary" @click="SendMsg(scope.row)">群发短信</el-button>
          <el-button size="mini" type="danger" v-if="scope.row.isAssesclose =='1'" @click="IsAsses(scope.row)">关闭班级评估</el-button>
          <el-button size="mini" type="success" v-if="scope.row.isAssesclose =='2'" @click="IsAsses(scope.row)">开启班级评估</el-button>
          <el-button size="mini" v-if="false" v-permission="['Classinformation:download']" type="info"
            style="font-size: 12px; color: white">
            <el-link :href="'https://bentuilong.oss-cn-shenzhen.aliyuncs.com/imgs/beihai/560240346676593401.xls'">下载模板
            </el-link>
          </el-button>
          <el-upload :action="url" :data="{classId:scope.row.classId}" class="upload-btn" accept=".xls, .xlsx"
            :on-success="handleAvatarSuccess" :show-file-list="false" :headers="toke">
            <el-button size="mini" type="primary" v-if="false" v-permission="['Classinformation:import']">导入名单
            </el-button>
          </el-upload>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="applytstwo" :title="title">
      <el-button @click="applytstwo = false">取 消</el-button>
      <el-button type="primary" @click="deletre('')">确定</el-button>
    </el-dialog>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
      :page-size="pageSize" layout="total,sizes,  prev, pager, next, jumper" :total="total" class="pag">
    </el-pagination>
    <!-- 通知 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span class="tcspan">请输入需要发送的信息</span>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ConfirmSending()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import {
    getUrl
  } from '@/utils/request.js'
  import {
    getAllClassByMoHu,
    refreshQR,
    goNotice,
    isAssesclose
  } from '@/api/user.js'
  import {
    del,
    QR
  } from '@/api/admin'
  import Loading from '@/components/loading/Loading.vue'
  import {
    mapMutations
  } from 'vuex'
  import {
    getCookie
  } from '@/utils/base'
  // import axios from 'axios' // 导入方法
  export default {
    name: 'Classinformation',
    components: {
      Loading
    },
    data() { // 数据模型
      return {
        year: '',
        // url:  getUrl() + '/bh/student/excelStudent',
        url: getUrl() + '/bh/student/excelStudent',
        toke: {
          Authorization: getCookie('Authorization')
        },
        title: '确定删除该班级？',
        applytstwo: false,
        showLoading: false,
        classId: '',
        dictionaryList4: [],
        rules1: {},
        from: '',
        orderby: '',
        className: '',
        opentest: {
          trainNumber: ''
        },
        opentestone: {
          registerOpenTime: ''
        },
        pageNum: 1,
        pageSize: 10,
        total: 0,
        dataList: [{
          category: '',
          train_location: '',
          id: '',
          className: '',
          train_number: '',
          register_open_time: '',
          sponsor: ''
        }],
        titlee: '填写报名信息',
        applyts: false,
        formLabelwidth: '100px',
        formLabeleft: '100px',
        dialogVisible: false,
        textarea: null,
        classIds: null
      }
    },
    mounted() { // 钩子函数
      // console.log('....')
      // console.log(getUrl())
      this.getAllClassByMoHu() // 初始化所有发布培训班信息
      // this.GetAllById(170) // 部门名称
    },
    computed: {}, // 计算属性
    methods: {
      getaddclass() {
        this.$router.push({
          path: '/Dashboard'
        })
      },
      // 导入功能
      handleAvatarSuccess(res, file) {
        console.log(res)
        if (res.code === '200') {
          this.$notify({
            title: '导入成功',
            type: 'success'
          })
        } else if (res.code === '405') {
          this.$notify({
            title: '报名人数已满',
            type: 'warning'
          })
        } else if (res.code === '406') {
          this.$notify({
            title: '请勿重复导入名单',
            type: 'warning'
          })
        } else {
          this.$notify({
            title: '导入失败',
            type: 'warning'
          })
        }
      },
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      handleModify(scope) {
        const ModifyItem = scope.row.classId
        this.$router.push({
          path: '/Dashboard',
          query: {
            id: ModifyItem
          }
        })
      },
      delet(row) {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((e) => {
          if (e === 'confirm') {
            del(row.classId).then(res => {
              if (res.data.code === '0') {
                this.$notify({
                  title: '删除成功',
                  type: 'success'
                })
                const index = this.dataList.indexOf(row)
                this.dataList.splice(index, 1)
                this.total--
              }
            })
          }
        }).catch(() => {})
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.getAllClassByMoHu()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getAllClassByMoHu()
      },
      // 刷新二维码
      Refresh(row) {
        refreshQR(row.classId).then(resp => {
          if (resp.data.code === '0') {
            this.$notify({
              title: '二维码已刷新',
              type: 'success'
            })
          } else {
            this.$notify({
              message: resp.data.msg,
              type: 'error'
            })
          }
        })
      },
      // 确定
      ConfirmSending() {
        goNotice({
          classIds: this.classIds,
          masMsg: this.textarea
        }).then((data) => {
          if (data.data.code === '200') {
            this.dialogVisible = false
            this.$notify({
              title: '发送成功',
              type: 'success'
            })
          }
        })
      },
      handleClose() {},
      // 群发短信
      SendMsg(row) {
        this.classIds = row.classId
        this.dialogVisible = true
      },
      getAllClassByMoHu: function() {
        const query = {
          className: this.className,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        getAllClassByMoHu(query).then(resp => {
          if (resp.data.code === '0') {
            this.total = resp.data.data.total // 查询总条数
            this.dataList = resp.data.data.rows // 接收返回的数据
          }
        })
      },
      QR(row) {
        QR(row.classId).then(resp => {})
      },
      // GetAllById (id) {
      //   GetAllById(id).then(resp => {
      //     if (resp.data.code === '0') {
      //       this.dictionaryList4 = resp.data.data
      //     }
      //   })
      // },
      classList(row) {
        this.$router.push({
          path: '/classList',
          query: {
            classId: row.classId,
            className: row.className
          }
        })
      },
      IsAsses(row) {
        var close
        if (row.isAssesclose === '1') {
          close = 2
        } else {
          close = 1
        }
        const query = {
          classId: row.classId,
          isAssesclose: close
        }
        isAssesclose(query).then(resp => {
          console.log(resp)
          if (resp.data.code === '0') {
            this.$notify({
              title: resp.data.msg,
              type: 'success'
            })
            this.getAllClassByMoHu()
          } else {
            this.$notify({
              message: resp.data.msg,
              type: 'warning'
            })
          }
        })
      }
    }
  }
</script>

<style>
  .el-input--small {
    font-size: 15px;
  }

  .tcspan {
    margin-bottom: 10px;
    display: block;
    float: left;
  }

  .class {
    width: 100%;
    background-color: white;
    position: relative;
    margin-top: -10px;
  }

  .top {
    width: 100%;
    height: 60px;
    background-color: #e9e9e9;
    display: flex;
    align-items: center;
  }

  .num {
    color: #2c2fd6;
  }

  .apply {
    position: absolute;
    left: 30px;
    margin: 0;
  }

  .inp {
    width: 250px;
    position: absolute;
    left: 180px;
    border: none;
  }

  .el-but {
    position: absolute;
    left: 440px;
  }

  .addclass {
    position: absolute;
    left: 540px;
  }

  .e-p {
    position: absolute;
    left: 720px;
    margin: 0;
  }

  .hig {
    width: 96%;
    border-bottom: 1px solid #cccccc;
    padding: 0 20px !important;
    display: flex;
  }

  .calss:last-child .hig {
    margin-bottom: 45px;
  }

  .left {
    height: auto;
    flex: 8;
  }

  .left-title {
    display: flex;
  }

  .left-cont {
    display: flex;
    justify-content: flex-start;
  }

  .left-tag {
    display: flex;
    justify-content: flex-start;
  }

  .left-tag p {
    margin-right: 30px;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 2;
  }

  .but {
    background: #ffc439;
    color: #fff;
    width: 40px;
    height: 20px;
    line-height: 20px;
    padding: 2px;
    border-radius: 2px;
  }

  .e-but {
    left: 85%;
  }

  .t {
    position: absolute;
    top: -301px;
    opacity: 0
  }

  .e-op {
    position: absolute;
    top: -400px;
    opacity: 0
  }

  .pag {
    position: absolute;
    right: 10px
  }

  .big-fa {
    display: flex
  }

  .big {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto
  }

  .big div {
    flex: 1
  }

  .timeone {
    position: relative;
    left: 760px;
    width: 80px;
  }

  .numtwo {
    position: relative;
    left: 760px;
    width: 80px;
    margin-left: 10px
  }

  .upload-btn {
    display: inline-block;
    margin: 0px 10px;
  }

  .white {
    color: #fff;
    text-decoration: none
  }

  .el-link.el-link--default {
    color: #fff;
  }

  .el-table .cell {
    line-height: 16px;
  }
</style>
